// Image Imports
import google from "../../assets/img/idx/rmloc/gsearch.webp";
import yahoo from "../../assets/img/idx/rmloc/yahoo.webp";
import bing from "../../assets/img/idx/rmloc/bing.webp";
import duckduckgo from "../../assets/img/idx/rmloc/duckduckgo.webp";
import baidu from "../../assets/img/idx/rmloc/baidu.webp";
import instagram from "../../assets/img/idx/rmloc/instagram.webp";
import youtube from "../../assets/img/idx/rmloc/youtube.webp";
import facebook from "../../assets/img/idx/rmloc/facebook.webp";
import reddit from "../../assets/img/idx/rmloc/reddit.webp";
import twitter from "../../assets/img/idx/rmloc/twitter.webp";
import tiktok from "../../assets/img/idx/rmloc/tiktok.webp";
import pinterest from "../../assets/img/idx/rmloc/pinterest.webp";
import linkedin from "../../assets/img/idx/rmloc/linkedin.webp";
import vimeo from "../../assets/img/idx/rmloc/vimeo.webp";
import blogger from "../../assets/img/idx/rmloc/blogger.webp";
import tumblr from "../../assets/img/idx/rmloc/tumblr.webp";


// Functional Component
export default function RemovalSection() {
  return (
    <div className="section-content monitor">
      <h2>Monitor</h2>
      <br/>
      <div className="section-group">
        <div className="links-group">
          <h4>We Monitor...</h4>
          <a href="/monitor/search-results">Search Results</a>
          <a href="/monitor/social-media">Social Media</a>
          <a href="/monitor/brand-mentions">Brand Mentions</a>
          <a href="/monitor/copycat-websites">Copycat Websites</a>
          <a href="/monitor/leaked-content">Leaked Content</a>
        </div>
      </div>
      <br/><br/>
      <div className="section-group">
        <div className="links-group">
          <h4>Search Engines</h4>
          <a href="/monitor/google-search-results">
            <img src={google.src} alt="Google" />
            Google
          </a>
          <a href="/monitor/yahoo-search-results">
            <img src={yahoo.src} alt="Yahoo" />
            Yahoo
          </a>
          <a href="/monitor/bing-search-results">
            <img src={bing.src} alt="Bing" />
            Bing
          </a>
          <a href="/monitor/duckduckgo-search-results">
            <img src={duckduckgo.src} alt="DuckDuckGo" />
            DuckDuckGo
          </a>
          <a href="/monitor/baidu-search-results">
            <img src={baidu.src} alt="Baidu" />
            Baidu
          </a>
        </div>
        <br/><br/>
        <div className="links-group">
          <h4>Social Media</h4>
          <a href="/monitor/instagram-content">
            <img src={instagram.src} alt="Instagram" />
            Instagram
          </a>
          <a href="/monitor/facebook-content">
            <img src={facebook.src} alt="Facebook" />
            Facebook
          </a>
          <a href="/monitor/reddit-content">
            <img src={reddit.src} alt="Reddit" />
            Reddit
          </a>
          <a href="/monitor/x-twitter-content">
            <img className="sm" src={twitter.src} alt="X / Twitter" />
            X / Twitter
          </a>
          <a href="/monitor/blogger-content">
            <img src={blogger.src} alt="Blogger" />
            Blogger
          </a>
        </div>
      </div>
    </div>
  );
} 